.elm-datetimepicker-duration--picker-container {
    color: secondary(60);
    font-size: fontSize(-1);
    border-radius: 2.5px;
    min-height: 30rem;
}

.elm-datetimepicker-duration--picker-header-chevrons {
    display: flex;
    justify-content: space-between;
}

.elm-datetimepicker-duration--picker-header-chevron {
    cursor: pointer;
}

.elm-datetimepicker-duration--picker-header-chevron:hover {
    color: #38c172;
}

.elm-datetimepicker-duration--calendars-container {
    display: flex;
}

.elm-datetimepicker-duration--calendar {
    display: block;
}



.elm-datetimepicker-duration--calendar-header-row {
    display: flex;
    padding: 0.5rem 0 1.75rem 0;
    justify-content: space-between;
}

.elm-datetimepicker-duration--calendar-header-text {
    display: inline-flex;
    font-size: 1.8rem;
    font-weight: bold;
    color: primary(30);
    align-items: center;
}

.elm-datetimepicker-duration--calendar-header-chevron {
    display: inline-flex;
    color: primary(30);
    align-items: center;
    cursor: pointer;
}

.elm-datetimepicker-duration--calendar-header-chevron:hover {
    color: secondary(0);
}

.elm-datetimepicker-duration--calendar-header-chevron.elm-datetimepicker-duration--hidden {
    visibility: hidden;
}

.elm-datetimepicker-duration--calendar-header-week {
    display: flex;
    color: secondary(10);
    font-weight: normal;
    padding: .5rem 0 .25rem 0
}

.elm-datetimepicker-duration--calendar-header-day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: len(4);
    flex: 1;
}

.elm-datetimepicker-duration--calendar-week {
    display: flex;
    padding: 0.10rem;
}

.elm-datetimepicker-duration--calendar-day {
    min-height: len(4);
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @extend .heading5Bold; //comes from _app-specific.scss
    color: primary(30);
    cursor: pointer;
    border: none;
    outline: 0;
}

.elm-datetimepicker-duration--calendar-day:hover {
    background-color: secondary(10);
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--hidden {
    visibility: hidden;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--disabled {
    color: secondary(10);
    cursor: default;
    background-color: #fff;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--picked {
    background-color: primary(30);
    color: #fff;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--today {
    color: primary(30);
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--between {
    background-color: secondary(10);
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--today-between {
    background-color: secondary(10);
    color: primary(30);
}

.elm-datetimepicker-duration--footer {
    display: none;
}



