gg-labeled-input {

  $ease: cubic-bezier(0.130, 0.845, 0.250, 0.995);
  .slidable {
    transition: transform .3s ease;
    transform: translateX(-100%);
    &.shown {
      transition-delay: .4s;
      transition-duration: 1.5s;
      transition-timing-function: $ease;
      transform: translateX(len(1));
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0; left: 0; right: 0;
    height: len(.24);
    background-color: transparentize(secondary(0), .875);
    pointer-events: none;
  }
}

gg-input {
  &::before {
    content: "";
    position: absolute;
    bottom: 0; left: 0; right: 0;
    height: len(.4);
    background-color: transparentize(secondary(0), .875);
    pointer-events: none;
  }

  input {
    &::placeholder {
      color: neutral(10);
    }
  }
}