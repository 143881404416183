.elm-datetimepicker-single--picker-container {
    background-color: primary(10);
    width: 100%;
    position: absolute;
    right: 0;
    top: 2px;
    color: secondary(60);
    z-index: 10;
    padding: len(1);
    font-size: fontSize(-1);
    border-radius: 2.5px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
    min-height: 30rem;
}

.elm-datetimepicker-single--calendar-header {
    padding: .25rem 0 0 0;
    font-weight: bold;
}

.elm-datetimepicker-single--calendar-header-row {
    display: flex;
    padding: 0.5rem 0 1.75rem 0;
    justify-content: space-between;
}

.elm-datetimepicker-single--calendar-header-text {
    display: inline-flex;
    font-size: 1.8rem;
    font-weight: bold;
    color: primary(30);
    align-items: center;
}

.elm-datetimepicker-single--calendar-header-chevron {
    display: inline-flex;
    color: primary(30);
    align-items: center;
    cursor: pointer;
}

.elm-datetimepicker-single--calendar-header-chevron:hover {
    color: secondary(0);
}

.elm-datetimepicker-single--calendar-header-week {
    display: flex;
    color: secondary(10);
    font-weight: normal;
    padding: .5rem 0 .25rem 0
}

.elm-datetimepicker-single--calendar-header-day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: len(4);
    flex: 1;
}

.elm-datetimepicker-single--calendar-week {
    display: flex;
    padding: 0.10rem;
}

.elm-datetimepicker-single--calendar-day {
    min-height: len(4);
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @extend .heading5Bold; //comes from _app-specific.scss
    color: primary(30);
    cursor: pointer;
    border: none;
    outline: 0;
}

.elm-datetimepicker-single--calendar-day:hover {
    background-color: secondary(10);
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--hidden {
    visibility: hidden;
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--disabled {
    color: secondary(10);
    cursor: default;
    background-color: #fff;
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--picked {
    background-color: primary(30);
    color: #fff;
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--today {
    color: primary(30);
}

.elm-datetimepicker-single--footer {
    display: none;
}