custom-select {
  open-icon {
    transition: transform 0.2s ease;
    transform: rotate(0);
  }

  &[opened] {
    dropdown-wrapper {
      box-shadow: 0 0 32px hsla(0, 0%, 0%, 0.1);
      max-height: len(50);

      dropdown-body {
        max-height: len(30);
        overflow-y: auto;
      }
    }

    open-icon {
      transform: rotate(180deg);
    }
  }
}

add-option-input-button {
  label {
    @extend .borderHairlineWidth, .borderSolid;
    border-color: transparent;
    input, button {
      opacity: 0;
    }
  }

  label:focus-within, label:active {
    @extend .gray20Border;

    span { display: none; }
    input, button {
      opacity: 1;
    }
  }
}