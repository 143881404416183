.optionBtnsList {
  > :not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    @extend .hairlineHeight;
    background: neutral(10);
  }

  &.lightStyle > :not(:last-child)::after {
    background: neutral(10);
    opacity: .2;
  }
}