simple-spinner.primary20BorderTop {
  border-top-color: primary(20);
}

simple-spinner {
  animation: spinnerRotate .6s infinite linear;
}

@keyframes spinnerRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}