nav-bar-bottom {
  $shadeLength: len(0.5);

  &.attached {
    nav-bar-body {
    }
    .shade {
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  &.detached {
    nav-bar-body {
      max-width: len(45);

      .navBarShade {
        box-shadow:
          0 0 3.5rem rgba(0, 0, 0, 0.1),
          0 2rem 1.5rem -1rem rgba(0, 0, 0, 0.2);
      }
    }
  }
}