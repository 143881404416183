
.chart-x-axis-labels {
  font-size: len(1.3);
  //letter-spacing: .1rem;
  //@extend .bodyBold;
}

.chart-y-axis-labels {
  font-size: len(1.4);
  letter-spacing: .1rem;
  //@extend .bodyBold;
}
