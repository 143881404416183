curtain {

  .curtainPane {
    transition: opacity .4s;

    &.brightBack {background: transparentize(neutral(20), .5);}
    &.darkBack {background: transparentize(primary(30), .3);}

    &.ENTERING {opacity: 1}
    &.EXITING {pointer-events: none}

    $dot-size: .5em;
    $duration: 3000ms;
    $container-size: $dot-size * 15;
    $dot-map: ( a:0, b:1, c:2, d:3, e:4, f:5, g:6, h:7);


    .spinnerContainer {
      width: $container-size;
      height: $container-size;

      .dot {
        content: "";
        border-radius: $dot-size * 0.5;
        margin-top: $dot-size * -0.5;
        margin-left: auto;
        margin-right: auto;
        width: $dot-size;
        height: $dot-size;

        animation-name: bounce;
        animation-duration: $duration;
        animation-iteration-count: infinite;
        transition-timing-function:  cubic-bezier(0, 0.99, 0, 0.1);
      }
    }

    .container-dot {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    @each $dot, $i in $dot-map {
      .dot-#{$dot} {
        transform: rotate(#{-360 / length($dot-map) * $i * 0.5}deg);
        .dot {
          animation-delay: -$duration / length($dot-map) * 0.5 * $i;
        }
      }
    }

    @keyframes bounce {
      0%, 100%   {
        transform: translatey(0);
      }
      50% {
        transform: translatey($container-size);
      }
    }

  }
}


