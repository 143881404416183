@mixin input-search-dropdown($maxHeight, $inputHeight) {
  @if type-of($maxHeight) != number {
    @error "mixin input-search-dropdown: $maxHeight argument is not a number";
  }
  @if type-of($inputHeight) != number {
    @error "mixin input-search-dropdown: $inputHeight argument is not a number";
  }

  &:active input,
  &:focus-within input,
  input:focus, input:active {
    opacity: 1;
    z-index: 3;
  }
  dropdown-wrapper {
    pointer-events: none;
  }

  @include on-input-search-dropdown-focused() {
    max-height: len($maxHeight);
    z-index: auto;

    dropdown-selected-option {
      opacity: 0;
    }

    dropdown-element {
      max-height: len($maxHeight - $inputHeight);
      overflow-y: auto;
    }
  }

  dropdown-icon {
    transition: transform 0.3s ease;
    transform-origin: center;
    transform: rotate(0deg);
  }

  input:focus + dropdown-wrapper + dropdown-icon-wrapper dropdown-icon,
  input + dropdown-wrapper:active + dropdown-icon-wrapper dropdown-icon {
    transform: rotate(-180deg);
  }
}

@mixin on-input-search-dropdown-focused() {
  input:focus + dropdown-wrapper,
  input + dropdown-wrapper:active {
    z-index: 2;
    @content;
  }
}