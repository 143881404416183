table {
}

.tableWrap::-webkit-scrollbar {
    height: 1em;
}

/* Track */
.tableWrap::-webkit-scrollbar-track {
    background: primary(10);
  border-radius: 100px;
}

/* Handle */
.tableWrap::-webkit-scrollbar-thumb {
    background: primary(30);
    border-radius: 15px;
}

/* Handle on hover */
// table::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }
.aggregate{
    right: 0px;
    position: sticky;
}