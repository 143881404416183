$graphColor: (
  0: hsla(207, 100%, 98%, 1),
  10: hsla(210, 33%, 90%, 1),
  20: hsla(191, 100%, 34%, 0.18),
  30: hsla(191, 100%, 34%, 0.3),
  40: hsla(191, 100%, 14%, 0.8)
);

$graphErrorColor: (
  0: hsla(328, 52%, 66%, 1),
  10: hsla(328, 53%, 51%, 1)
);

@each $colorKey in map-keys($graphColor){
  .graph#{$colorKey}Color { color: getOrFail($graphColor, $colorKey); }
  .graph#{$colorKey}Back { background-color: getOrFail($graphColor, $colorKey); }
}

@each $colorKey in map-keys($graphErrorColor){
  .error#{$colorKey}Color { color: getOrFail($graphErrorColor, $colorKey); }
  .error#{$colorKey}Back { background-color: getOrFail($graphErrorColor, $colorKey); }
}

.whiteTransparentBack { background-color: hsla(0, 0%, 100%, 0.7); }

.placeholderFix {
  &::placeholder {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
}

.hidePlaceholderOnFocus {
  &:focus::placeholder {
    opacity: 0;
  }
}

@each $colorKey in map-keys($primaryPalette) { .afterPrimary#{$colorKey}Back::after { background-color: primary($colorKey) } }
@each $colorKey in map-keys($neutralPalette) { .afterGray#{$colorKey}Back::after { background-color: neutral($colorKey) } }

[underlineType]::after {
    content: "";
    height: 0.25rem;
    position: absolute;
}

[underlineType="selection-primary"]::after {
  left: 0;
  width: 100%;
  bottom: -1.185rem;
}

[underlineType="item-primary"]::after {
  width: 50%;
  bottom: -0.185rem;
}

[underlineType="item-gray"]::after {
  width: 50%;
  bottom: -0.185rem;
}

.focusWithinSecondary0Border:focus-within { @extend .secondary0Border }
.focusWithinPrimary30:focus-within { @extend .primary30 }

.scrollHoverPrimary {
  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 2rem;
    }
    &::-webkit-scrollbar-thumb {
      width: 1rem;
      border: .5rem solid rgb(255, 255, 255);
      background-color: primary(30);
      border-radius: 5rem;
    }
  }
  &::-webkit-scrollbar {
    width: 2rem;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
  }
}

.scrollHoverSecondary {
  &:hover {
    //&::-webkit-scrollbar {
    //  @extend .whiteBack;
    //  border-radius: 2rem;
    //}
    &::-webkit-scrollbar-track {
      @extend .whiteBack;
      border-radius: 2rem;
    }
    &::-webkit-scrollbar-thumb {
      width: 1rem;
      border: .5rem solid rgb(255, 255, 255);
      background-color: neutral(40);
      border-radius: 5rem;
    }
  }
  &::-webkit-scrollbar {
    width: 2rem;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
  }
}