$clBrandPrimary: hsl(33, 89%, 57%);
$clBrandSecondary: hsl(200, 100%, 39%);
$gray10: hsl(204, 15%, 94%);

$unitSize1: 6;
$unitSize2: 6;
$unitSize3: 6;
$unitSize4: 8;
$fontFamily: sans-serif;
$fontSizes: (-2:0.8, -1:0.9, 0:1.0, '0h':1.2, 1:1.4, 2:1.6, 3:2, 4:2.4, 5:2.5, 6:6);
$fontWeights: (300, 400, 500, 700);
$letterSpacings: (0:0, 1:0.025, 2:-0.01);
$borderRadius: (0:.5, 1:10, 2:1, 3:.25, 4:12.5, 5:3, 6:3.5);
$ease: cubic-bezier(0.270, 0.300, 0.000, 1.000);
$phoneWidthUnits: 54;
$desktopWidthUnits: 130;
$biggerThanDesktopWidthUnits : 145;
$betweenPhoneDesktopWidthUnits : 88;

$neutralPalette: (
  0: hsl(210, 29%, 97%),
  10: hsl(207, 31%, 94%),
  20:hsl(218, 21%, 84%),
  40:hsl(218, 17%, 73%),
  60:hsl(217, 10%, 54%),
  70:hsl(0, 0%, 85%),
  80:hsl(214, 16%, 27%),
  90:hsl(0,0%,0%)
);

$primaryPalette: (
  0: hsl(0, 0%, 100%),
  10:hsl(0, 0%, 98%),
  20:hsl(247, 80%, 52%),
  30:hsl(232, 100%, 24%)
);

$secondaryPalette: (
  0:hsl(257, 74%, 52%),
  10:hsl(236, 88%, 97%),
  11:hsl(236, 100%, 94%),
  20:hsl(228, 13%, 25%),
  30:hsl(89, 73%, 48%),
  40:hsl(33, 100%, 50%),
  50:hsl(360, 78%, 52%),
  51:hsl(0, 76%, 95%),
  60:hsla(247, 80%, 52%, 0.1),
  70:hsla(236, 88%, 97%,1),
  80: hsla(113,70%,37%,1),
  90: hsl(4, 79%, 68%)
);


$gradients: (
  0:linear-gradient(180deg, hsl(4, 79%, 68%) 0%, hsl(12, 81%, 67%) 100%),
  10:linear-gradient(359.72deg, hsl(240, 10%, 96%) 62.04%, hsla(240, 10%, 96%, 0) 98.85%),
  20:linear-gradient(270deg, rgba(255,255,255, 1) 62.04%, rgba(255,255,255, 0) 103%),
);
