.scrollableNestedTable {
  .nestingLine{
      width: .25rem;
      background-color: neutral(10);
      position: absolute;
      top: 100%;
      left: 1rem;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background-color: hsla(0, 0%, 98%, 1);
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: hsl(232, 100%, 24%);
      border-radius: 1rem;
      border: 0.5rem solid hsla(0, 0%, 98%, 1);
    }
  }
  tr th:last-child,
  tr td:last-child {
    position: sticky;
    right: 0;
    min-width: 12rem;
    padding: 0 2rem 0 0;
    text-align: right;
    font-weight: 600;
    &::after {
      content: '';
      width: 1px;
      height: 150%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: #dce7ef;
    }
  }
  tr:last-child td:last-child {
    &::after {
      height: 100% !important;
    }
  }
  tr td:last-child {
    color: hsl(4, 79%, 68%);
    background-color: inherit;
    text-align: right;
    padding-left: 0;
  }
  th {
    padding: 0 2rem 0 0;
  }
  tr th:last-child {
    color: hsl(232, 100%, 24%);
    background-color: white;
  }
  tr th:first-child,
  tr td:first-child {
    position: sticky;
    left: 0;
    padding-left: 2rem;
  }
  tr th:first-child {
    background-color: white;
    text-align: left;
  }
  tr td:first-child {
    background-color: inherit;
  }
  tr td {
    text-align: right;
    padding: 2rem 2rem 2rem 0;
    max-height: 7rem;
  }

  [indent-level='0'] {
    td:first-of-type {
      
    }
  }
  [indent-level='1'] {
    // background-color: black;
    .circle1{
      width: .25rem;
      background-color: neutral(10);
      height: 50% ;
      position: absolute;
      top: 0;
      left: - 1rem;
      
      &::after{
        content: '';
        height: .5rem;
        width: .5rem;
        border-radius: 50%;
        border: .25rem solid neutral(10);
        background-color: white;
        position: absolute;
        transform: translate(-37.5%,-50%);
        top: 100%;
        left: 0;
      }
      &.noTlastChild::before{
        content: '';
        width: .25rem;
        background-color: neutral(10);
        height: 100% ;
        position: absolute;
        top: 100%;
        left: 0;
      }

    }
    td:first-of-type {
      border-left: 2rem solid white;
      // margin-left: 2rem;
      no-op-el,
      button {
        position: relative;
        // &::before {
        //   height: 0.5rem;
        //   width: 0.5rem;
        //   border-radius: 50%;
        //   border: .25rem solid neutral(10);
        //   background-color: white;
        //   position: absolute;
        //   transform: translateY(-50%);
        //   top: 50%;
        // }
        // &::after {
        //   content: '';
        //   height: 7rem;
        //   width: 2px;
        //   background-color: hsl(207, 31%, 94%);
        //   position: absolute;
        //   transform: translateX(-50%);
        //   bottom: calc(50% + 0.5rem);
        //   left: -3rem;
        //   z-index: 2;
        // }
      }
      // span {
      // }
      // &::after {
      //   content: '';
      //   height: 100%;
      //   width: 2rem;
      //   background-color: white;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }
    }
  }
  [indent-level='2'] {
    td:first-of-type {
      border-left: 4rem solid white;
      padding-left: 2rem;
      &.parentIsNotLastChild::after{
        content: '';
        width: .25rem;
        background-color: neutral(10);
        height: 100% ;
        position: absolute;
        top: 0;
        left: -3rem;
      }
      .circle1{
        width: .25rem;
        background-color: neutral(10);
        height: 50% ;
        position: absolute;
        top: 0;
        left: - 1rem;
        
        &::after{
          content: '';
          height: .5rem;
          width: .5rem;
          border-radius: 50%;
          border: .25rem solid neutral(10);
          background-color: white;
          position: absolute;
          transform: translate(-37.5%,-50%);
          top: 100%;
          left: 0;
        }
        &.noTlastChild::before{
          content: '';
          width: .25rem;
          background-color: neutral(10);
          height: 100% ;
          position: absolute;
          top: 100%;
          left: 0;
        }
  
      }
      // no-op-el,
      // button {
      //   position: relative;
      //   &::before {
      //     content: '';
      //     height: 0.5rem;
      //     width: 0.5rem;
      //     border-radius: 50%;
      //     border: 2px solid hsl(207, 31%, 94%);
      //     background-color: white;
      //     position: absolute;
      //     transform: translateY(-50%);
      //     top: 50%;
      //     left: -3.5rem;
      //   }
      //   // &::after {
      //   //   content: '';
      //   //   height: 7rem;
      //   //   width: 2px;
      //   //   background-color: hsl(207, 31%, 94%);
      //   //   position: absolute;
      //   //   transform: translateX(-50%);
      //   //   bottom: calc(50% + 0.5rem);
      //   //   left: -3rem;
      //   //   z-index: 2;
      //   // }
      // }
      // span {
      // }
      // &::before {
      //   content: '';
      //   height: 100%;
      //   width: 4rem;
      //   background-color: white;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }
      // &::after {
      //   content: '';
      //   height: 8.5rem;
      //   width: 2px;
      //   background-color: hsl(207, 31%, 94%);
      //   position: absolute;
      //   transform: translateX(-50%);
      //   bottom: calc(40%);
      //   left: 1rem;
      //   z-index: 3;
      // }
    }
  }
  [indent-level='3'] {
    td:first-of-type {
      border-left: 6rem solid white;
      padding-left: 2rem;
      &.grandParentIsNotLastChild::after{
        content: '';
        width: .25rem;
        background-color: neutral(10);
        height: 100% ;
        position: absolute;
        top: 0;
        left: -5rem;
      }
      &.parentIsNotLastChild::before{
        content: '';
        width: .25rem;
        background-color: neutral(10);
        height: 100% ;
        position: absolute;
        top: 0;
        left: -3rem;
      }
      .circle1{
        width: .25rem;
        background-color: neutral(10);
        height: 50% ;
        position: absolute;
        top: 0;
        left: - 1rem;
        
        &::after{  // circle
          content: '';
          height: .5rem;
          width: .5rem;
          border-radius: 50%;
          border: .25rem solid neutral(10);
          background-color: white;
          position: absolute;
          transform: translate(-37.5%,-50%);
          top: 100%;
          left: 0;
        }
        &.noTlastChild::before{
          content: '';
          width: .25rem;
          background-color: neutral(10);
          height: 100% ;
          position: absolute;
          top: 100%;
          left: 0;
        }
  
      }
      // no-op-el,
      // button {
      //   position: relative;
      //   &::before {
      //     content: '';
      //     height: 0.5rem;
      //     width: 0.5rem;
      //     border-radius: 50%;
      //     border: 2px solid hsl(207, 31%, 94%);
      //     background-color: white;
      //     position: absolute;
      //     transform: translateY(-50%);
      //     top: 50%;
      //     left: -3.5rem;
      //   }
      //   // &::after {
      //   //   content: '';
      //   //   height: 7rem;
      //   //   width: 2px;
      //   //   background-color: hsl(207, 31%, 94%);
      //   //   position: absolute;
      //   //   transform: translateX(-50%);
      //   //   bottom: calc(50% + 0.5rem);
      //   //   left: -3rem;
      //   // }
      // }
      // &::after {
      //   content: '';
      //   height: 8.5rem;
      //   width: 2px;
      //   background-color: hsl(207, 31%, 94%);
      //   position: absolute;
      //   transform: translateX(-50%);
      //   bottom: calc(40%);
      //   left: 1rem;
      //   z-index: 3;
      // }
      // div {
      //   position: relative;
      //   &::before {
      //     content: '';
      //     height: 9rem;
      //     width: 2px;
      //     background-color: hsl(207, 31%, 94%);
      //     position: absolute;
      //     transform: translateX(-50%);
      //     bottom: -1.5rem;
      //     left: -5rem;
      //     z-index: 3;
      //   }
      // }
    }
  }

  .rowExpanded {
    background-color: hsl(207, 31%, 94%);
  }
  // Start ScrollBar  -------------------------------------------------------

  table {
    margin-bottom: 3rem;
  }
  &::-webkit-scrollbar {
    height: 2rem;
    transition: 1s;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
  }

  &::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 100%);
  }

  // End ScrollBar ----------------------------------------------------------
}

.scrollableNestedTable2 {
  thead tr:first-child th:last-child {
    position: sticky;
    right: 0;
  }

  thead tr:last-child th:last-child,
  tr td:last-child {
    right: 0;
    position: sticky;
    //width: 12rem;
    padding: 2rem;
    text-align: center;
    font-weight: 600;
  }

  thead tr:last-child th:nth-last-child(2),
  tr td:nth-last-child(2) {
    right: 14rem;
    position: sticky;
    //width: 12rem;
    padding: 2rem;
    text-align: center;
    font-weight: 600;
  }

  thead tr:last-child th:nth-last-child(3),
  tr td:nth-last-child(3) {
    right: 28rem;
    position: sticky;
    //width: 12rem;
    padding: 2rem;
    text-align: center;
    font-weight: 600;
  }

  tr td:nth-last-child(3) {
    &::after {
      content: '';
      width: 1px;
      height: 120%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: #dce7ef;
    }
  }
  tr td:last-child {
    color: hsl(4, 79%, 68%);
    background-color: inherit;
  }
  tr td:nth-last-child(2),
  tr td:nth-last-child(3) {
    background-color: inherit;
  }
  tr th:last-child,
  tr th:nth-last-child(2),
  tr th:nth-last-child(3) {
    background-color: white;
  }
  tr:first-child th {
    color: hsl(232, 100%, 24%);
    background-color: white;
  }
  thead tr:last-child th:nth-last-child(3) {
    &::after {
      content: '';
      width: 1px;
      height: 120%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: #dce7ef;
    }
  }
  tr th:first-child,
  tr td:first-child {
    position: sticky;
    left: 0;
    padding-left: 2rem;
  }
  tr th:first-child {
    background-color: white;
    text-align: left;
  }
  tr td:first-child {
    background-color: inherit;
  }
  tr td {
    text-align: right;
    padding-right: 0.5rem;
    padding-left: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-height: 7rem;
  }

  [indent-level='0'] {
  }
  [indent-level='1'] {
    td:first-of-type {
      padding-left: 4rem;
      no-op-el,
      button {
        position: relative;
        &::before {
          content: '';
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 50%;
          border: 2px solid hsl(207, 31%, 94%);
          background-color: white;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: -3.5rem;
        }
        &::after {
          content: '';
          height: 7rem;
          width: 2px;
          background-color: hsl(207, 31%, 94%);
          position: absolute;
          transform: translateX(-50%);
          bottom: calc(50% + 0.5rem);
          left: -3rem;
        }
      }
      span {
      }
      &::after {
        content: '';
        height: 100%;
        width: 2rem;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  [indent-level='2'] {
    td:first-of-type {
      padding-left: 6rem;
      no-op-el,
      button {
        position: relative;
        &::before {
          content: '';
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 50%;
          border: 2px solid hsl(207, 31%, 94%);
          background-color: white;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: -3.5rem;
        }
        &::after {
          content: '';
          height: 7rem;
          width: 2px;
          background-color: hsl(207, 31%, 94%);
          position: absolute;
          transform: translateX(-50%);
          bottom: calc(50% + 0.5rem);
          left: -3rem;
        }
      }
      span {
      }
      &::before {
        content: '';
        height: 100%;
        width: 4rem;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after {
        content: '';
        height: 8.5rem;
        width: 2px;
        background-color: hsl(207, 31%, 94%);
        position: absolute;
        transform: translateX(-50%);
        bottom: calc(40%);
        left: 1rem;
      }
    }
  }
  [indent-level='3'] {
    td:first-of-type {
      padding-left: 8rem;
      no-op-el,
      button {
        position: relative;
        &::before {
          content: '';
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 50%;
          border: 2px solid hsl(207, 31%, 94%);
          background-color: white;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: -3.5rem;
        }
        &::after {
          content: '';
          height: 7rem;
          width: 2px;
          background-color: hsl(207, 31%, 94%);
          position: absolute;
          transform: translateX(-50%);
          bottom: calc(50% + 0.5rem);
          left: -3rem;
        }
      }

      &::before {
        content: '';
        height: 100%;
        width: 6rem;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after {
        content: '';
        height: 8.5rem;
        width: 2px;
        background-color: hsl(207, 31%, 94%);
        position: absolute;
        transform: translateX(-50%);
        bottom: calc(40%);
        left: 1rem;
      }
      div {
        position: relative;
        &::before {
          content: '';
          height: 9rem;
          width: 2px;
          background-color: hsl(207, 31%, 94%);
          position: absolute;
          transform: translateX(-50%);
          bottom: -1.5rem;
          left: -5rem;
        }
      }
    }
  }
  .rowExpanded {

    background-color: hsl(207, 31%, 94%);
  
  }
}
