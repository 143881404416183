slider-element { /** todo: disabled styles */
  track-element {
    border-radius: 100px;

    fill-element {
      border-radius: 100px;
      transition: transform 1.3s cubic-bezier(0.05, 0.51, 0.58, 0.99);
    }
  }

  thumb-wrapper {
    transition: transform 0.3s cubic-bezier(0.01, 0.93, 0.58, 0.99);

    thumb-element {
      height: 2rem;
      width: 2rem;
    }
  }

  input[type=range] {
    min-height: 2rem;

    &[orient=vertical] {
      min-height: 100px;
      width: 2rem;
      appearance: slider-vertical;

      & + track-element {
        max-width: 1rem;
      }
    }
  }
}
