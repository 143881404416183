//font-family: 'Karla', sans-serif;
//font-family: 'Roboto', sans-serif;


@import "vars";

.heroBold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(8);
  line-height: 1.25em;
}

.heroRegular64 {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(8);
  line-height: 1.25em;
}

.heading1Bold48 {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(6);
  line-height: 1.17em;
}

.heading2Bold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(4);
  line-height: 1.24em;
}

.heading3Bold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(3);
  line-height: 1.32em;
}

.heading4Bold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(2);
  line-height: 1.5em;
}

.heading5Bold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(1.75);
  line-height: 1.7em;
}

.heading6Regular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(1.5);
  line-height: 1.3em;
}

.heading6Bold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(1.5);
  line-height: 1.3em;
}

.heading1Regular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(6);
  line-height: 1.17em;
}

.heading2Regular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(4);
  line-height: 1.24em;
}

.heading3Regular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(3);
  line-height: 1.32em;
}

.heading4Regular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(2);
  line-height: 1.5em;
}

.heading5Regular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(1.75);
  line-height: 1.7em;
}

// .heading6Bold {
//   font-family: 'Karla', "Open Sans", sans-serif ;
//   font-style: normal;
//   font-weight: 400;
//   font-size: len(1.5);
//   line-height: 1.3em;
// }

.bodyBold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(2);
  line-height: 1.5em;
}

.bodyRegular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(2);
  line-height: 1.5em;
}

.noteBold {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: len(1.25);
  line-height: 1.56em;
}

.noteRegular {
  font-family: 'Karla', "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: len(1.25);
  line-height: 1.56em;
}

.hairLineHeight {
  height: len(0.125);
}

.doubleHairLineHeight {
  height: len(0.25);
}

.doubleHairLineWidth {
  width: len(0.25);
}

.borderHairlineWidth {
  border-width: len(0.125);
}

.borderHairline {
  border: solid len(0.125) neutral(10);
}

.borderTopHairline {
  border-top: solid len(0.125) neutral(10);
}

.borderRightHairline {
  border-right: solid len(0.125) neutral(10);
}

.borderBottomHairline {
  border-bottom: solid len(0.125) neutral(10);
}

.borderLeftHairline {
  border-left: solid len(0.125) neutral(10);
}

toaster {
  width: 80%;
  margin: 0 auto;

  .toastBackground {
    border-radius: 4px;
    background: gradients(0);
  }

  .toastBackgroundShade {
    opacity: 0.0;
  }
}

.maxW7 {
  max-width: 7rem;
}

.minW10 {
  min-width: 10rem;
}

.w70 {
  max-width: 70rem;
}

.slightShadow {
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.1);
}

.moveTop50 {
  transform: translateY(-50%);
}

.minW65 {
  min-width: 65rem;
}

.w34 {
  width: 34rem;
}

.w44 {
  min-width: 44rem;
}

.maxW46 {
  max-width: 46rem;
}

.maxW50 {
  max-width: 50rem;
}

.minW69 {
  min-width: 69rem;
}

.maxW90 {
  max-width: 90rem;
}

.maxW94 {
  max-width: 94rem;
}

.maxW108 {
  max-width: 108rem;
}

.maxW118 {
  max-width: 118rem;
}

.w120 {
  width: 120rem;
}

.maxW130 {
  max-width: 130rem;
}

.minW130 {
  min-width: 130rem;
}

.tableRowSpacing {
  border-collapse: separate;
  border-spacing: 0 3rem;
}

.exceptionMaxDesktopW {
  max-width: 160rem;
  margin-left: auto;
  margin-right: auto;
}

.transformY {
  transform: translateY(-1px);
}

.imgWidthHeight {
  max-width: 50%;
  max-height: 50%;
}

.underlineGray {
  text-decoration: underline;
  color: hsl(232, 100%, 24%);
  padding-top: 2rem;
}

.verticalText {
  writing-mode: vertical-rl;
}

@each $number in 41 46 64 {
  .w#{$number} {
    width: lenRound($number);
    min-width: lenRound($number)
  }

  .h#{$number} {
    height: lenRound($number);
    min-height: lenRound($number)
  }

  .maxW#{$number} {
    max-width: lenRound($number)
  }

  .maxH#{$number} {
    max-height: lenRound($number)
  }

  .minW#{$number} {
    min-width: lenRound($number)
  }

  .minH#{$number} {
    min-height: lenRound($number)
  }

  .pad#{$number} {
    padding: lenRound($number)
  }

  .lineH#{$number} {
    line-height: lenRound($number)
  }

  html .padTop#{$number} {
    padding-top: lenRound($number)
  }

  html .padRight#{$number} {
    padding-right: lenRound($number)
  }

  html .padBottom#{$number} {
    padding-bottom: lenRound($number)
  }

  html .padLeft#{$number} {
    padding-left: lenRound($number)
  }

  //halves
  .w#{$number}h {
    width: lenRound($number + .5);
    min-width: lenRound($number + .5)
  }

  .h#{$number}h {
    height: lenRound($number + .5);
    min-height: lenRound($number + .5)
  }

  .minW#{$number}h {
    min-width: lenRound($number + .5)
  }

  .minH#{$number}h {
    min-height: lenRound($number + .5)
  }

  .pad#{$number}h {
    padding: lenRound($number + .5)
  }

  html .padTop#{$number}h {
    padding-top: lenRound($number + .5)
  }

  html .padRight#{$number}h {
    padding-right: lenRound($number + .5)
  }

  html .padBottom#{$number}h {
    padding-bottom: lenRound($number + .5)
  }

  html .padLeft#{$number}h {
    padding-left: lenRound($number + .5)
  }

  .lineH#{$number}h {
    line-height: lenRound($number + .5)
  }
}


.chartGradientLeft {
  width: 10%;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.79) 0%,
      rgba(255, 255, 255, 0.6) 9%,
      rgba(255, 255, 255, 0) 100%);
}

.chartGradientRight {
  width: 10%;
  background: linear-gradient(-90deg,
      rgba(255, 255, 255, 0.79) 0%,
      rgba(255, 255, 255, 0.6) 9%,
      rgba(255, 255, 255, 0) 100%);
}



.tableRowspace {
  border-bottom: solid white 1rem;
  border-top: solid white 1rem;
}


.specialW {
  width: 1.2rem;
  min-width: 1.2rem;

}


.lineH2em {
  line-height: 2em;
}

.popOverBtnHover {
  transition: .3s ease;

  &:hover {
    background-color: secondary(70);
  }
}

.scrollTarget {
  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 2rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 1rem;
      border: .5rem solid rgb(255, 255, 255);
      background-color: primary(30);
      border-radius: 5rem;
    }
  }

  &::-webkit-scrollbar {
    width: 2rem;
  }

  &::-webkit-scrollbar-track {}

  &::-webkit-scrollbar-thumb {}
}