toaster {
  z-index: 1500; // it should have higher z-index then photo-swipe

  .toastBackground {
    background-color: black;
  }
  .toastBackgroundShade {
    background-color: black;
  }

  .toastsSlidingWrap {
    transition: transform 2s;
  }
  .aToast {
    transition: transform 2s; //top bar offsetting
    animation: 1s cubic-bezier(0.150, 0.660, 0.145, 0.980) 0s slideFromBottomAnimation;

    .shade {
      bottom: -20%;
      transition: opacity .3s;
      animation: 1.8s cubic-bezier(0.175, 0.075, 0.150, 0.935) 0s slideFromBottomShadeAnimation;
      animation-fill-mode: forwards;
      animation-direction: normal;
    }

    &.outOfSight {
      .shade { opacity: 0  }
    }
  }
}


@keyframes slideFromBottomAnimation {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes slideFromBottomShadeAnimation {
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0%);
  }
}

